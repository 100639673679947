import { createAsyncThunk, createSlice, SliceCaseReducers } from '@reduxjs/toolkit'
import { getParticipants } from '../api/getParticipants'

interface ParticipantState {
    entries: Novoic.API.Participant[]
    loading: boolean
}

export const participantSlice = createSlice<ParticipantState, SliceCaseReducers<ParticipantState>>({
    name: 'participants',
    initialState: {
        entries: [],
        loading: true,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchParticipants.pending, (state) => {
            if (state.entries.length === 0) {
                state.loading = true
            }
        })
        builder.addCase(fetchParticipants.fulfilled, (state, action) => {
            state.entries = action.payload
            state.loading = false
        })
        builder.addCase(fetchParticipants.rejected, (state) => {
            state.loading = false
        })
    },
})

export const fetchParticipants = createAsyncThunk(
    'participants/fetchParticipants',
    async (query?: string): Promise<Novoic.API.Participant[]> => {
        const participants = await getParticipants(query)
        return participants
    }
)
