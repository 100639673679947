.item {
    @apply p-3 rounded-lg mb-2 transition-all text-gray-600;
}

.item:not(.active):hover .icon,
.item:not(.active):hover .title {
    @apply text-gray-900;
}

.icon {
    @apply w-6 mr-4 transition-all;
}

.title {
    @apply font-medium text-sm;
}

.active {
    @apply item bg-indigo-50;

    .icon {
        @apply text-indigo-500 stroke-2;
    }

    .title {
        @apply text-gray-900;
    }
}
