import { XMarkIcon } from '@heroicons/react/24/outline'
import BackIcon from '../../../assets/back.svg'
import QR from 'react-qr-code'
import { useModal } from '../../../hooks/useModal'
import { ShareAssignment } from './ShareAssignment'

interface QRCodeProps {
    invite: Novoic.API.AssignmentInvitation
}

export function QRCode(props: QRCodeProps) {
    const [open, close] = useModal()
    return (
        <div className="w-[416px]">
            <div className="flex justify-between items-center gap-4 mb-6">
                <div className="flex h-[28px]">
                    <BackIcon
                        onClick={() =>
                            open({
                                content: <ShareAssignment data={props.invite} />,
                            })
                        }
                        className="cursor-pointer fill-gray-900"
                    />
                </div>
                <button className="w-6 h-6" onClick={close}>
                    <XMarkIcon />
                </button>
            </div>
            <div className="flex justify-center px-[64px] pt-[32px] pb-[80px] items-center ">
                <QR value={props.invite.link} />
            </div>
        </div>
    )
}
