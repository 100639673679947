import { api } from '../api'

interface CreateAssignmentForm {
    assignment_timeout: number
    study_id: string
    battery_id: string
    patient_id: string
    max_assignments: number
    language: string
    labels?: Record<string, unknown>
}

export async function createAssignmentInvitation(
    form: CreateAssignmentForm
): Promise<Novoic.API.AssignmentInvitation> {
    const { data } = await api.post<Novoic.API.AssignmentInvitation>(
        '/private/assignmentsInvitations',
        form
    )
    return data
}
