export function generateLoader<T>(
    loader: (...args: unknown[]) => Promise<T>,
    filter?: string
): (...args: unknown[]) => Promise<T> {
    return async function ({ request }: { request: Request }) {
        const pagination = new URLSearchParams(new URL(request.url).search)
        const query = new URLSearchParams(filter)

        if (pagination.has('offset')) {
            query.append('offset', pagination.get('offset'))
        }

        if (pagination.has('limit')) {
            query.append('limit', pagination.get('limit'))
        }

        return loader(query.toString())
    }
}
