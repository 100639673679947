import { useLocalStorage } from './useLocalStorage'

interface UserAPI {
    getUser: () => Novoic.User | undefined
    getToken: () => string | undefined
    isLoggedIn: () => boolean
    logIn: (user: Novoic.User, token: string) => void
    logOut: () => void
}

const USER = 'user'
const TOKEN = 'token'

export function useUserAPI(): UserAPI {
    const [persist, retrieve, erase] = useLocalStorage()

    function logIn(user: Novoic.User, token: string): void {
        persist(USER, user)
        persist(TOKEN, token)
    }

    function logOut() {
        erase(USER)
        erase(TOKEN)
    }

    function getUser(): Novoic.User | undefined {
        const user = retrieve<Novoic.User>(USER)

        if (user) {
            return user
        }

        return undefined
    }

    function getToken(): string | undefined {
        const token = retrieve<string>(TOKEN)

        if (token) {
            return token
        }

        return undefined
    }

    function isLoggedIn(): boolean {
        const user = getUser()
        return user != null
    }

    return {
        getUser,
        getToken,
        isLoggedIn,
        logIn,
        logOut,
    }
}
