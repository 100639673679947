import { XCircleIcon } from '@heroicons/react/20/solid'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import NovoicDashboardLogo from '../assets/novoic-dashboard-logo.svg'
import { useUserAPI } from '../hooks/useUserAPI'
import { TextInput } from './form/TextInput'
import { useLogin } from '../hooks/useLogin'

export default function Login() {
    const login = useLogin()
    const navigate = useNavigate()
    const userAPI = useUserAPI()
    const [error, setError] = useState<Error>()
    const [isPending, setIsPending] = useState<boolean>(false)

    if (userAPI.isLoggedIn()) {
        navigate('/app')
    }

    async function onSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault()
        setIsPending(true)

        const formData = new FormData(e.currentTarget)
        const payload: Record<string, string> = {}

        for (const [key, value] of formData.entries()) {
            payload[key] = value as string
        }

        try {
            const data = await login(payload.email, payload.password)
            setError(undefined)
            userAPI.logIn(data.user, data.token)
            navigate('/app')
        } catch (e: unknown) {
            setError(e as Error)
        } finally {
            setIsPending(false)
        }
    }

    return (
        <div className="nv-grid min-h-screen">
            <div className="md-container max-h-full items-center content-center justify-center col-start-1 md:col-start-2 lg:col-start-5">
                <div className="w-full max-w-md space-y-8">
                    <div className="flex flex-col items-center gap-y-4">
                        <NovoicDashboardLogo className="w-56" />
                        <h2 className="text-center text-3xl font-bold font-serif text-gray-900">
                            Log in to your account
                        </h2>
                    </div>
                    <form className="relative mt-8 space-y-6" action="#" onSubmit={onSubmit}>
                        <input type="hidden" name="remember" defaultValue="true" />
                        <div className="-space-y-px rounded-md shadow-sm">
                            <div>
                                <label htmlFor="login" className="sr-only">
                                    Email
                                </label>
                                <TextInput
                                    id="login"
                                    name="email"
                                    type="text"
                                    autoComplete="username"
                                    required
                                    className="rounded-t-md px-3 py-2"
                                    placeholder="Email"
                                    validationError="Please, fill in your login (SID)"
                                    error={error}
                                />
                            </div>
                            <div>
                                <label htmlFor="password" className="sr-only">
                                    Password
                                </label>
                                <TextInput
                                    id="password"
                                    name="password"
                                    type="password"
                                    autoComplete="current-password"
                                    required
                                    className="rounded-b-md px-3 py-2"
                                    placeholder="Password"
                                    validationError="Please, fill in your password"
                                    error={error}
                                />
                            </div>
                        </div>

                        <div>
                            <button
                                type="submit"
                                disabled={isPending}
                                className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-base font-medium text-white disabled:opacity-75 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                                Log In
                            </button>
                        </div>

                        <div
                            className={`w-full rounded-md bg-red-50 p-4 transition-opacity ${
                                error ? 'opacity-100' : 'opacity-0'
                            }`}
                        >
                            <div className="flex">
                                <div className="flex-shrink-0">
                                    <XCircleIcon
                                        className="h-5 w-5 text-red-400"
                                        aria-hidden="true"
                                    />
                                </div>
                                <div className="ml-3">
                                    <h3 className="text-sm font-medium text-red-800">
                                        Incorrect login or password
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
