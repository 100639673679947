import { api } from '../api'

export async function listAssignmentInvitations(
    search: string
): Promise<Novoic.API.AssignmentInvitation[]> {
    const { data: assignmentInvitations } = await api.get(
        '/private/assignmentsInvitations' + (search ? `?${search}` : '')
    )
    return assignmentInvitations
}
