import axios, { AxiosError } from 'axios'
import config from '../config'

interface VerifySuccess {
    token: string
    user: Novoic.User
}

function isForbidden(status: number): boolean {
    return status === 403
}

export function useVerifyUser(): (
    email: string,
    password: string,
    code: string
) => Promise<VerifySuccess> {
    const attemptVerify = async (name: string, password: string, code: string) => {
        try {
            const { data } = await axios.post<VerifySuccess>(`${config.api}/auth/verify/${code}`, {
                name,
                password,
            })
            return data
        } catch (e: unknown) {
            const { status } = (e as AxiosError).response
            if (isForbidden(status)) {
                throw new Error('Failed to verify')
            }
        }
    }

    return attemptVerify
}
