import { ExclamationCircleIcon } from '@heroicons/react/20/solid'
import { ChangeEvent, useEffect } from 'react'
import { useState } from 'react'

interface TextInputProps
    extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    label?: string | JSX.Element
    validationError?: string
    error: Error
}

const normalClasses = 'border-gray-300 focus:border-indigo-500  focus:ring-indigo-500'
const errorClasses =
    'border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500'

export function TextInput({
    error,
    label,
    validationError,
    className,
    ...attributes
}: TextInputProps) {
    const [isPristine, setPristine] = useState<boolean>(true)

    useEffect(() => {
        setPristine(true)
    }, [error])

    const showError = error && isPristine

    function onChange(e: ChangeEvent<HTMLInputElement>) {
        attributes.onChange?.(e)

        setPristine(false)
    }

    return (
        <div className="relative">
            {label && (
                <div className="mb-1">
                    <label
                        htmlFor="external_id"
                        className="block text-sm font-medium text-gray-700"
                    >
                        {label}
                    </label>
                </div>
            )}
            <input
                {...attributes}
                className={`relative block w-full appearance-none rounded-none border text-gray-900 placeholder-gray-500 focus:z-10 focus:outline-none xs:text-sm transition-all ${className} ${
                    showError ? errorClasses : normalClasses
                }`}
                onChange={onChange}
                onInvalidCapture={(e) =>
                    (e.target as HTMLInputElement).setCustomValidity(validationError)
                }
                onInput={(e) => (e.target as HTMLInputElement).setCustomValidity('')}
            />
            {showError && (
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                </div>
            )}
        </div>
    )
}
