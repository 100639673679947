interface DateInputProps {
    id: string
    name: string
    placeholder: string
    label: string
    value: string
    onChange: (value: string) => void
    error?: string
}

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

export function DateInput(props: DateInputProps) {
    const { id, name, label, value, onChange, error } = props

    return (
        <div>
            <div className="mb-1">
                <label htmlFor={id} className="text-sm font-medium text-gray-700">
                    {label}
                </label>
            </div>
            <input
                type="date"
                id={id}
                name={name}
                defaultValue={value.split('T')[0]}
                className={classNames(
                    'block h-[38px] w-full shadow-sm sm:text-sm border-gray-300 rounded-md',
                    error ? 'border-red-600' : 'focus:ring-indigo-500 focus:border-indigo-500'
                )}
                onChange={(e) => onChange(new Date(e.target.value).toISOString())}
            />

            {error && (
                <div className="mt-2 text-sm text-red-600">
                    <span>{error}</span>
                </div>
            )}
        </div>
    )
}
