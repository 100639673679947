{
    "genderOptions": [
        {
            "title": "Male",
            "value": "Male"
        },
        {
            "title": "Female",
            "value": "Female"
        },
        {
            "title": "Other",
            "value": "Other"
        },
        {
            "title": "Prefer not to say",
            "value": "Prefer not to say"
        }
    ],
    "languageOptions": [
        {
            "title": "English",
            "value": "English"
        },
        {
            "title": "Spanish",
            "value": "Spanish"
        }
    ],
    "educationOptions": [
        { "title": "Less than 9th grade", "value": "Less than 9th grade" },
        { "title": "Some high school", "value": "Some high school" },
        { "title": "High school graduate", "value": "High school graduate" },
        { "title": "Some college", "value": "Some college" },
        { "title": "Associate’s degree", "value": "Associate’s degree" },
        { "title": "Bachelor’s degree", "value": "Bachelor’s degree" },
        { "title": "Post-graduate degree", "value": "Post-graduate degree" }
    ],
    "races": [
        {
            "title": "Latino/a",
            "value": "Latino/a"
        },
        {
            "title": "Black or African American",
            "value": "Black or African American"
        },
        {
            "title": "White",
            "value": "White"
        },
        {
            "title": "American Indian or Alaska Native",
            "value": "American Indian or Alaska Native"
        },
        {
            "title": "Asian",
            "value": "Asian"
        },
        {
            "title": "Native Hawaiian or Other Pacific Islander",
            "value": "Native Hawaiian or Other Pacific Islander"
        }
    ]
}
