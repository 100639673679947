import { ChevronDownIcon } from '@heroicons/react/24/outline'
import PlusIcon from '../assets/plus.svg'
import { useModal } from '../hooks/useModal'
import { formatDuration } from '../utils/formatDuration'
import { ShareAssignment } from './modals/content/ShareAssignment'
import { formatStatusLabel } from '../utils/formatStatusLabel'

interface ParticipantCardTestsProps {
    tests: unknown[]
    canCreateTest: boolean
    onCreateTest: () => void
}

const columns = [
    'Test type',
    'Status',
    'Score',
    'Quality control',
    'Completed',
    'Assigned',
    'Duration',
    'Device',
    'Assignment ID',
]

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

function isHoverableRow(status: string) {
    return ['complete', 'incomplete', 'cancelled', 'expired', 'timeout'].indexOf(status) === -1
}

function formatQC(qc: boolean) {
    if (qc === undefined) {
        return formatOutput(qc)
    }

    return qc ? 'Passed' : 'Failed'
}

function formatDate(date: string) {
    if (!date) {
        return formatOutput(date)
    }
    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        timeZone: 'UTC',
        hour: 'numeric',
        minute: 'numeric',
    }
    return formatOutput(new Intl.DateTimeFormat('en-US', options).format(new Date(date)))
}

function formatOutput(input: string | number | boolean): JSX.Element {
    return input == undefined ? <span className="text-gray-400">n/a</span> : <span>{input}</span>
}

function formatScore(score: number, level?: string) {
    if (score === undefined) return formatOutput(score)

    return (
        <>
            <span className="text-xs font-medium rounded-[10px] py-0.5 px-2 bg-indigo-500 text-white">
                {formatOutput(Math.round(score))}
            </span>
            {level && <span className="pl-1 text-sm font-medium">{level}</span>}
        </>
    )
}

export function ParticipantCardTests(props: ParticipantCardTestsProps) {
    const [open] = useModal()

    function onTestClick(test: Novoic.ParticipantTestListItem) {
        if (!test.invite) {
            return
        }

        const invite = test.invite as Novoic.API.AssignmentInvitation

        open({
            content: <ShareAssignment data={invite} />,
        })
    }

    return (
        <>
            <div className="flex flex-col divide-y divide-gray-200">
                <div className="pt-8 pb-6 px-8">
                    <h2 className="text-xl font-semibold text-gray-900">
                        Tests{' '}
                        <span className="text-xl font-normal text-gray-500">
                            {props.tests.length}
                        </span>
                    </h2>
                </div>
                <table>
                    <thead className="border-b">
                        <tr>
                            {columns.map((col) => (
                                <th
                                    key={col}
                                    scope="col"
                                    className="py-2.5 px-3 first:pl-8 text-left text-sm font-medium text-gray-900 whitespace-nowrap"
                                >
                                    <button className="group inline-flex">
                                        {col}
                                        <span className="invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible">
                                            <ChevronDownIcon
                                                className="h-5 w-5"
                                                aria-hidden="true"
                                            />
                                        </span>
                                    </button>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                        {props.tests.map((test: Novoic.ParticipantTestListItem) => (
                            <tr
                                key={test.id}
                                onClick={() => onTestClick(test)}
                                className={classNames(
                                    isHoverableRow(test.status)
                                        ? 'hover:bg-[#f7f9ff] cursor-pointer transition-all'
                                        : '',
                                    test.invite ? '' : 'disabled'
                                )}
                            >
                                <td
                                    className="whitespace-nowrap pl-8 py-4 text-sm text-gray-600"
                                    role="presentation"
                                >
                                    {formatOutput(test.battery)}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600">
                                    {formatStatusLabel(test.status)}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600">
                                    {formatScore(test.score, test.scoreLevel)}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600">
                                    {formatQC(test.qc)}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600">
                                    {formatDate(test.completed)}
                                </td>
                                <td className="px-3 py-4 text-sm text-gray-600">
                                    {formatDate(test.assigned)}
                                </td>
                                <td className="px-3 py-4 text-sm text-gray-600">
                                    {formatDuration(test.duration)}
                                </td>
                                <td className="px-3 py-4 text-sm text-gray-600">
                                    {formatOutput(test.device)}
                                </td>
                                <td className="px-3 py-4 text-sm text-gray-600">
                                    {formatOutput(test.assignmentId)}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {props.canCreateTest && (
                <div className="px-8 py-8">
                    <button
                        className="flex items-center text-sm font-medium text-indigo-600"
                        onClick={props.onCreateTest}
                    >
                        <PlusIcon className="mr-2 fill-indigo-600" />
                        Assign Another Test
                    </button>
                </div>
            )}
        </>
    )
}
