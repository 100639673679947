const statusMapStyle: Record<Novoic.AssignmentStatus, string> = {
    empty: 'bg-gray-100 text-gray-800',
    overdue: 'bg-yellow-100 text-yellow-800',
    'not started': 'bg-gray-100 text-gray-800',
    incomplete: 'bg-indigo-100 text-indigo-800',
    assigned: 'bg-indigo-100 text-indigo-800',
    complete: 'bg-indigo-500 text-white',
    cancelled: 'bg-red-100 text-red-800',
    expired: 'bg-red-100 text-red-800',
}

const statusMap: Record<string, string> = {
    'not started': 'Not started',
    incomplete: 'In progress',
    assigned: 'Assigned',
    complete: 'Completed',
    cancelled: 'Failed',
    expired: 'Failed',
}

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

export function formatStatusLabel(status: Novoic.AssignmentStatus, title?: string) {
    return (
        <span
            className={classNames(
                'text-xs font-medium rounded-[10px] py-0.5 px-2',
                statusMapStyle[status]
            )}
        >
            {title || statusMap[status]}
        </span>
    )
}
