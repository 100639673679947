import { api } from '../api'
import config from '../config'
import { Deferred } from '../utils/Deferred'

interface API {
    list(): Promise<Novoic.API.Study[]>
}

export function useStudy(): API {
    async function list(): Promise<Novoic.API.Study[]> {
        const def = new Deferred<Novoic.API.Study[]>()

        try {
            const { data } = await api.get(config.api + '/private/studies')
            def.resolve(data)
        } catch (err) {
            def.reject(err)
        }

        return def.promise
    }

    return { list }
}
