import { PropsWithChildren, createContext, useState } from 'react'
import { Modal } from './Modal'
import { CSSTransition } from 'react-transition-group'
import * as css from './Modal.module.css'

export interface ModalConfig {
    content: JSX.Element
    confirmText?: string
    cancelText?: string
    canClose?: boolean
    onConfirm?: () => Promise<unknown>
    onClose?: () => Promise<unknown>
    onCancel?: () => Promise<unknown>
}

export interface ModalAPI {
    open: (config: ModalConfig) => void
    close: () => void
}

export const ModalContext = createContext<ModalAPI>(undefined)

export function ModalAPIContext({ children }: PropsWithChildren) {
    const [modalConfig, setModalConfig] = useState<ModalConfig>({
        content: null,
    })

    const [isOpen, setIsOpen] = useState<boolean>(false)

    const API: ModalAPI = {
        async open(modalConfig: ModalConfig) {
            setModalConfig(modalConfig)
            setIsOpen(true)
        },
        async close() {
            setIsOpen(false)
        },
    }

    function onConfirm() {
        setIsOpen(false)
        modalConfig.onConfirm?.()
        return Promise.resolve()
    }

    function onCancel() {
        modalConfig.onCancel?.()
        setIsOpen(false)
        return Promise.resolve()
    }

    function onClose() {
        modalConfig.onClose?.()
        setIsOpen(false)
        return Promise.resolve()
    }

    return (
        <ModalContext.Provider value={API}>
            {children}
            <CSSTransition
                in={isOpen}
                timeout={300}
                mountOnEnter
                unmountOnExit
                classNames={{
                    enter: css.transition_enter,
                    enterActive: css.transition_enter_active,
                    exit: css.transition_exit,
                    exitActive: css.transition_exit_active,
                }}
            >
                <Modal
                    content={modalConfig.content}
                    canClose={modalConfig.canClose}
                    confirmText={modalConfig.confirmText}
                    cancelText={modalConfig.cancelText}
                    onConfirm={onConfirm}
                    onCancel={onCancel}
                    onClose={onClose}
                />
            </CSSTransition>
        </ModalContext.Provider>
    )
}
