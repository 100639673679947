import { createAsyncThunk, createSlice, SliceCaseReducers } from '@reduxjs/toolkit'
import { fetchParticipants } from './participantSlice'
import { RootState } from '../store'
import { api } from '../api'
import { fetchAssignmentInvitations } from './assignmentInviteSlice'

interface CombinedParticipantListState {
    entries: Novoic.CombinedParticipantItem[]
    loading: boolean
}

export const combinedParticipantListSlice = createSlice<
    CombinedParticipantListState,
    SliceCaseReducers<CombinedParticipantListState>
>({
    name: 'assignmentInvitations',
    initialState: {
        entries: [],
        loading: true,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchCombinedParticipantList.pending, (state) => {
            if (state.entries.length === 0) {
                state.loading = true
            }
        })
        builder.addCase(fetchCombinedParticipantList.fulfilled, (state, action) => {
            const { assignmentInvites, participants } = action.payload

            state.entries = participants.map((participant) => {
                const assignmentInvitation = assignmentInvites.find(
                    (invitation) => invitation.patient_id === participant.id
                )
                return {
                    ...participant,
                    assignmentInvitation,
                }
            })
            state.loading = false
        })
        builder.addCase(fetchCombinedParticipantList.rejected, (state) => {
            state.loading = false
        })
        builder.addCase(updateParticipantStatus.fulfilled, (state, action) => {
            state.entries = action.payload.entities
        })
    },
})

export const fetchCombinedParticipantList = createAsyncThunk(
    'combinedParticipantList/fetchCombinedParticipantList',
    async (query: string, { dispatch, getState }) => {
        await dispatch(fetchParticipants(query))

        let state = getState() as RootState

        const ids = state.participants.entries.map((participant) => participant.id)
        const filter = ids.map((id: string) => `patients=${id}`).join('&')

        await dispatch(fetchAssignmentInvitations(filter))

        state = getState() as RootState

        return {
            assignmentInvites: state.assignmentInvites.entries,
            participants: state.participants.entries,
        }
    }
)

interface UpdateParticipantStatusPayload {
    patient_id: string
    status: string
    study_id: string
}

export const updateParticipantStatus = createAsyncThunk(
    'participants/updateParticipantStatus',
    async (payload: UpdateParticipantStatusPayload[], { getState }) => {
        await api.post('/private/patients/status', payload)

        const state = getState() as RootState
        const entities = state.combinedParticipants.entries.filter((participant) => {
            return !payload.find((p) => p.patient_id === participant.id)
        })

        return { entities, participants: payload }
    }
)
