import { useEffect } from 'react'
import { PropsWithChildren } from 'react'
import { useNavigate } from 'react-router-dom'
import { useUserAPI } from '../hooks/useUserAPI'

interface ProtectedRouteProps extends PropsWithChildren {
    redirectPath?: string
}

export function ProtectedRoute({ children, redirectPath = '/' }: ProtectedRouteProps) {
    const { getUser } = useUserAPI()
    const navigate = useNavigate()
    const user = getUser()

    useEffect(() => {
        if (!user) {
            navigate(redirectPath)
            return
        }
    }, [navigate, user, redirectPath])

    return <>{children}</>
}
