import { Listbox, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import CrossSmallIcon from '../assets/cross-small.svg'
import CheckSmallIcon from '../assets/check-small.svg'
import BookmarkSmallIcon from '../assets/bookmark-small.svg'
import EyeIcon from '../assets/eye.svg'

interface ReviewButtonProps {
    value: string
    num?: number
    onChange: (value: string) => void
}

const items = [
    {
        name: 'Awaiting review',
        value: undefined,
        selectedIcon: <EyeIcon className="p-0.5 w-6 h-6 mr-2" />,
        listIcon: <EyeIcon className="p-0.5 w-6 h-6" />,
    },
    {
        name: 'Not qualified',
        value: 'excluded',
        selectedIcon: <CrossSmallIcon className="p-0.5 w-6 h-6 mr-2" />,
        listIcon: <CrossSmallIcon className="p-0.5 w-6 h-6" />,
    },
    {
        name: 'Qualified',
        value: 'selected',
        selectedIcon: <CheckSmallIcon className="p-0.5 w-6 h-6 mr-2" />,
        listIcon: <CheckSmallIcon className="p-0.5 w-6 h-6" />,
    },
    {
        name: 'Invited',
        value: 'bookmarked',
        selectedIcon: <BookmarkSmallIcon className="p-0.5 w-6 h-6 mr-2" />,
        listIcon: <BookmarkSmallIcon className="p-0.5 w-6 h-6" />,
    },
]

export default function ReviewButton({ num, value, onChange }: ReviewButtonProps) {
    const current = items.find((item) => item.value === value)

    console.log(value)

    return (
        <div className="flex rounded-md h-10">
            <Listbox
                as="div"
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                className="relative -ml-px block rounded-md shadow-sm"
                value={current?.value}
                onChange={onChange}
            >
                {current && (
                    <Listbox.Button className="relative inline-flex items-center rounded-md border h-10 px-2.5 py-2.5 text-xs font-medium text-white focus:bg-gray-50 active:bg-gray-50">
                        <div className="relative inline-flex items-center pr-2.5 text-sm font-medium text-gray-900">
                            {current.selectedIcon}
                            {current.name}
                            {num && num > 1 && (
                                <div className="ml-2 px-2 rounded-[4px] bg-gray-50">{num}</div>
                            )}
                        </div>
                        <ChevronDownIcon className="h-5 w-5 fill-gray-900" aria-hidden="true" />
                    </Listbox.Button>
                )}

                <Transition
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Listbox.Options className="absolute left-0 z-10 mt-2 -mr-1 w-[312px] origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="divide-y">
                            {items
                                .filter((item) => item.value !== current?.value)
                                .map((item) => (
                                    <Listbox.Option
                                        key={item.name}
                                        className="w-full group trigger"
                                        value={item.value}
                                    >
                                        <div className="flex items-center p-4 cursor-pointer text-left hover:bg-indigo-50">
                                            {item.listIcon}
                                            <div className="ml-3">
                                                <div className="flex text-sm font-medium text-gray-900">
                                                    <span>{item.name}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Listbox.Option>
                                ))}
                        </div>
                    </Listbox.Options>
                </Transition>
            </Listbox>
        </div>
    )
}
