import { useState, useEffect } from 'react'
import config from '../config'

function getStaticURL(file: string) {
    return config.s3.buckets.static + '/' + file
}

export function useFonts(): boolean {
    const [areFontsLoaded, setAreFontsLoaded] = useState<boolean>(false)

    useEffect(() => {
        const fonts: FontFace[] = [
            new FontFace(
                'Gelica',
                `url(${getStaticURL('08b919ee-494b-4a67-a796-d7932290f8de.woff2')})`
            ),
            new FontFace('Inter', `url(${getStaticURL('Inter+Web/Inter-Light.woff2')})`, {
                weight: '400',
            }),
            new FontFace('Inter', `url(${getStaticURL('Inter+Web/Inter-Medium.woff2')})`, {
                weight: '500',
            }),
            new FontFace('Inter', `url(${getStaticURL('Inter+Web/Inter-SemiBold.woff2')})`, {
                weight: '600',
            }),
            new FontFace('Inter', `url(${getStaticURL('Inter+Web/Inter-Bold.woff2')})`, {
                weight: '700',
            }),
        ]

        Promise.all(fonts.map((font) => font.load()))
            .then((fonts) => {
                fonts.forEach((font) => document.fonts.add(font))
            })
            .finally(() => {
                setAreFontsLoaded(true)
            })
    }, [])

    return areFontsLoaded
}
