import LogoutIcon from '../assets/logout.svg'
import Logo from '../assets/logo.svg'
import { logout } from '../utils/logout'
import { ParticipantsMenuItem } from './menu/ParticipantsMenuItem'

export function SideMenu() {
    return (
        <section className="fixed inset-0 min-h-screen flex flex-col w-64 bg-white border-r shrink-0 px-4 pt-8 pb-4 z-20">
            <div className="flex mb-8 w-[224px] h-[40px] justify-center items-center">
                <Logo className="mr-3" />
                <span className="font-serif font-normal text-xl pr-1.5">Novoic</span>
                <span className="font-normal text-lg">Dashboard</span>
            </div>

            {/* <button
                type="button"
                className="inline-flex items-center justify-center rounded-md border border-gray-300 bg-white w-full mb-10 px-4 py-2.5 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                onClick={() =>
                    open({
                        canClose: true,
                        content: <NewAssignment />,
                    })
                }
            >
                <PlusIcon className="w-5 h-5 mr-2" />
                <span className="text-sm">Create New Test</span>
            </button> */}

            {/* <div className="p-4 rounded-lg hover:bg-indigo-50 cursor-pointer mb-2">
                <div className="flex items-center">
                    <ChartBarSquareIcon className="w-[18px] mr-4" />
                    <span className="font-medium text-sm text-gray-600">Monitoring</span>
                </div>
            </div> */}

            {/* <TestsMenuItem /> */}

            <ParticipantsMenuItem />

            {/* 
                <div className="p-4 rounded-lg hover:bg-indigo-50 cursor-pointer mb-2">
                    <div className="flex items-center cursor-pointer">
                        <UserCircleIcon className="w-[18px] mr-4" />
                        <span className="font-medium text-sm text-gray-600">Staff</span>
                    </div>
                </div>

                <div className="p-4 rounded-lg hover:bg-indigo-50 cursor-pointer mb-2">
                    <div className="flex items-center cursor-pointer">
                        <Cog8ToothIcon className="w-[18px] mr-4" />
                        <span className="font-medium text-sm text-gray-600">Settings</span>
                    </div>
                </div>
            */}

            <div className="flex grow items-end">
                <div className="w-full">
                    {/* <div className="flex bg-gray-50 p-4 rounded-2xl mb-10">
                        <CloudIcon className="w-[18px] mr-3" />
                        <span className="text-gray-500 text-sm">Synchronized</span>
                    </div> */}
                    <button
                        onClick={logout}
                        className="flex h-[60px] gap-3 p-3 items-center rounded-lg cursor-pointer group container transition-all hover:bg-indigo-100"
                    >
                        <LogoutIcon className="w-6 h-6 stroke-gray-400 stroke-2 transition-all group-[.container:hover]:stroke-indigo-500" />
                        <div className="flex flex-col">
                            <div className="flex flex-col items-start transition-all translate-y-3 group-[.container:hover]:-translate-y-0 group-[.container:hover]:opacity-0 ">
                                <span className="text-sm font-medium text-gray-600">
                                    Cody Rater
                                </span>
                                <span className="text-xs font-regular text-gray-500">
                                    Mayo Clinic Arizona
                                </span>
                            </div>
                            <div className="flex transition-all opacity-0 group-[.container:hover]:-translate-y-[18px] group-[.container:hover]:opacity-100">
                                <span className="text-sm font-medium text-gray-900">Logout</span>
                            </div>
                        </div>
                    </button>
                </div>
            </div>
        </section>
    )
}
