import { createContext, PropsWithChildren, useEffect, useState } from 'react'
import { Toast } from './Toast'

export interface ToastConfig {
    icon: JSX.Element
    title: string
    description?: string
    canClose?: boolean
    timeout?: number
}

export interface ToastContextType {
    open: (toastConfig: ToastConfig) => void
    close: () => void
}

export const ToastContext = createContext<ToastContextType>(undefined)

export function ToastAPIContext({ children }: PropsWithChildren) {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [config, setConfig] = useState<ToastConfig>()

    useEffect(() => {
        if (!isOpen) return

        setTimeout(() => {
            setIsOpen(false)
        }, 3000)
    }, [isOpen])

    const API = {
        async open(toastConfig: ToastConfig) {
            setConfig(toastConfig)
            setIsOpen(true)
        },
        async close() {
            setConfig(undefined)
            setIsOpen(false)
        },
    }

    return (
        <ToastContext.Provider value={API}>
            {children}
            <div
                aria-live="assertive"
                className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 z-20"
            >
                <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
                    <Toast isOpen={isOpen} onClose={API.close} {...config} />
                </div>
            </div>
        </ToastContext.Provider>
    )
}
