import { useMemo } from 'react'
import {
    createBrowserRouter,
    createRoutesFromElements,
    Navigate,
    Route,
    RouterProvider,
} from 'react-router-dom'
import CreateAccount from './components/CreateAccount'
import { Layout } from './components/Layout'
import Login from './components/Login'
import { PublicRoot } from './components/PublicRoot'
import { useAppDispatch } from './hooks/useRedux'
import { getParticipantRoutes } from './routes/participants'

export function Router() {
    const dispatch = useAppDispatch()

    const router = useMemo(
        () =>
            createBrowserRouter(
                createRoutesFromElements(
                    <Route path="/" element={<PublicRoot />}>
                        <Route path="/login" element={<Login />} />
                        <Route path="/invite" element={<CreateAccount />} />
                        <Route path="/app" element={<Layout />}>
                            <Route path="participants">
                                {getParticipantRoutes(dispatch).map((route) =>
                                    route.index ? (
                                        <Route
                                            key={'participants-index'}
                                            index
                                            element={route.element}
                                        />
                                    ) : (
                                        <Route
                                            key={route.path}
                                            path={route.path}
                                            element={route.element}
                                            loader={route.loader}
                                        />
                                    )
                                )}
                            </Route>
                            <Route index element={<Navigate to="participants" replace />} />
                        </Route>
                        <Route index element={<Navigate to="/login" replace />} />
                    </Route>
                )
            ),
        [dispatch]
    )

    return <RouterProvider router={router} />
}
