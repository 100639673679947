import { UserGroupIcon } from '@heroicons/react/24/outline'
import PlusIcon from '../assets/plus.svg'
import { PrimaryButton } from './elements/PrimaryButton'

interface ParticipantListEmptyProps {
    onCreateTest: () => void
}

export function ParticipantListEmpty({ onCreateTest }: ParticipantListEmptyProps) {
    return (
        <div className="flex flex-col grow justify-center px-8 py-8">
            <div className="flex self-center flex-col items-center">
                <div className="flex justify-center items-center w-16 h-16 rounded-full bg-indigo-50 mb-4">
                    <UserGroupIcon className="w-6 h-6 stroke-indigo-500 stroke-2" />
                </div>
                <h2 className="mb-3 text-gray-900 text-xl font-semibold">
                    Participants will appear here
                </h2>
                <p className="text-base text-gray-600">
                    Create new Participants by pressing the blue button below
                </p>
                <p className="mb-6 text-base text-gray-600">
                    {' '}
                    or use the “+” icon in the sidebar menu
                </p>
                <PrimaryButton onClick={onCreateTest}>
                    <PlusIcon className="mr-2 fill-white" />
                    Create Participant
                </PrimaryButton>
            </div>
        </div>
    )
}
