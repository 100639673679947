import { CheckCircleIcon } from '@heroicons/react/20/solid'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import NovoicDashboardLogo from '../assets/novoic-dashboard-logo.svg'
import { useUserAPI } from '../hooks/useUserAPI'
import { useVerifyUser } from '../hooks/useVerifyUser'

export default function CreateAccount() {
    const verify = useVerifyUser()
    const userAPI = useUserAPI()
    const [isPending, setIsPending] = useState<boolean>(false)
    const [password, setPassword] = useState<string>('')
    const [repeatPassword, setRepeatPassword] = useState<string>('')
    const [passwordValid, setPasswordValid] = useState<boolean>(false)
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()

    const token = searchParams.get('code')

    const passwordRequirements = useMemo(
        () => [
            {
                test: (password: string) => password.length >= 8,
                description: 'At least 8 characters',
            },
            {
                test: (password: string) => /[a-z]/.test(password),
                description: 'At least 1 lowercase character',
            },
            {
                test: (password: string) => /[A-Z]/.test(password),
                description: 'At least 1 uppercase character',
            },
            {
                test: (password: string) => /\d/.test(password),
                description: 'At least 1 number',
            },
            {
                test: (password: string) => /[!@#$%^&*-]/.test(password),
                description: 'At least 1 special character (!@#$%^&*)',
            },
        ],
        []
    )

    useEffect(() => {
        setPasswordValid(
            passwordRequirements.every((req) => req.test(password)) && password === repeatPassword
        )
    }, [password, repeatPassword, passwordRequirements])

    async function onSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault()
        setIsPending(true)

        const formData = new FormData(e.currentTarget)
        const payload: Record<string, string> = {}

        for (const [key, value] of formData.entries()) {
            payload[key] = value as string
        }

        try {
            const data = await verify(payload.name, payload.password, token)
            userAPI.logIn(data.user, data.token)
            navigate('/app')
        } catch (e: unknown) {
            //
        } finally {
            setIsPending(false)
        }
    }

    return (
        <div className="nv-grid min-h-screen">
            <div className="md-container max-h-full items-center content-center justify-center col-start-1 md:col-start-2 lg:col-start-5">
                <div className="w-full max-w-md space-y-8">
                    <div className="flex flex-col items-center gap-y-4">
                        <NovoicDashboardLogo className="w-56" />
                        <h2 className="text-center text-3xl font-bold font-serif text-gray-900">
                            Create your account
                        </h2>
                    </div>
                    <form className="relative mt-8 space-y-6" action="#" onSubmit={onSubmit}>
                        <div className="flex flex-col gap-y-4">
                            <div>
                                <label
                                    htmlFor="name"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Your full name
                                </label>
                                <input
                                    id="name"
                                    name="name"
                                    type="text"
                                    autoComplete="name"
                                    required
                                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                />
                            </div>
                            <div>
                                <label
                                    htmlFor="password"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Create password
                                </label>
                                <input
                                    id="password"
                                    name="password"
                                    type="password"
                                    autoComplete="new-password"
                                    required
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                />
                                <ul className="flex flex-col gap-y-1 mt-2 mb-2 text-sm text-gray-600">
                                    {passwordRequirements.map((req, index) => (
                                        <li
                                            key={index}
                                            className={`flex items-center ${
                                                req.test(password)
                                                    ? 'line-through text-gray-500'
                                                    : ''
                                            }`}
                                        >
                                            {req.test(password) ? (
                                                <CheckCircleIcon
                                                    className="h-5 w-5 -ml-0.5 mr-0.5 text-indigo-500"
                                                    aria-hidden="true"
                                                />
                                            ) : (
                                                <div
                                                    className="h-4 w-4 mr-1 border rounded-full border-gray-300 text-white"
                                                    aria-hidden="true"
                                                />
                                            )}
                                            {req.description}
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            <div>
                                <label
                                    htmlFor="repeatPassword"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Repeat password
                                </label>
                                <input
                                    id="repeatPassword"
                                    name="repeatPassword"
                                    type="password"
                                    autoComplete="new-password"
                                    required
                                    value={repeatPassword}
                                    onChange={(e) => setRepeatPassword(e.target.value)}
                                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                />
                            </div>
                        </div>

                        <div>
                            <button
                                type="submit"
                                className={`group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-base font-medium text-white disabled:opacity-75 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 ${
                                    passwordValid && !isPending ? 'visible' : 'invisible'
                                }`}
                            >
                                Continue
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
