import { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import BackIcon from '../assets/back.svg'
import AIBadge from '../assets/ai-badge.svg'
import { getParticipant } from '../api/getParticipant'
import { StudyCtx } from '../contexts/StudyContext'
import { ParticipantCardEmpty } from './ParticipantCardEmpty'
import { ShareAssignment } from './modals/content/ShareAssignment'
import { useModal } from '../hooks/useModal'
import { createAssignmentInvitation } from '../api/createAssignmentInvitation'
import { ParticipantCardTests } from './ParticipantCardTests'
import { useParticipantTestsList } from '../hooks/useParticipantTestsList'
import ReviewButton from './ReviewButton'
import { api } from '../api'

// TODO: Rewrite this shit as soon as we have proper API with enums and batery selection
function mapPropsToData({ language, patientId, studyId }: Record<string, string>) {
    const batteryId =
        language === 'English'
            ? '928666da-7eb8-46c9-b4cc-03bd6f3c82d5'
            : '0b0599d3-78a9-4b3b-8481-e2fa4ce74442'
    const lang = language === 'English' ? 'en-US' : 'es'

    return {
        patient_id: patientId,
        battery_id: batteryId,
        study_id: studyId,
        language: lang,
        max_assignments: 1,
        assignment_timeout: 30 * 60,
    }
}

function formatDate(date: string) {
    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        timeZone: 'UTC',
        hour: 'numeric',
        minute: 'numeric',
    }
    return new Intl.DateTimeFormat('en-US', options).format(new Date(date))
}

function formatRecommendation(recommended?: boolean) {
    if (recommended === undefined) return formatOutput(recommended)

    return recommended ? 'Qualified' : 'Not qualified'
}

function formatDOB(date: string) {
    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        timeZone: 'UTC',
    }
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(new Date(date))
    const ageDifMs = Date.now() - new Date(date).getTime()
    const ageDate = new Date(ageDifMs)

    const fullYears = Math.abs(ageDate.getUTCFullYear() - 1970)
    return `${formattedDate} (${fullYears} years)`
}

function formatOutput(input: string | number | boolean): JSX.Element {
    return input == undefined ? <span className="text-gray-400">n/a</span> : <span>{input}</span>
}

function formatScore(score?: number) {
    if (score === undefined) return formatOutput(score)

    return formatOutput(Math.round(score))
}

export function ParticipantCard() {
    const { patientId } = useParams()
    const [open] = useModal()
    const study = useContext(StudyCtx)
    const [fetchIdx, setFetchIdx] = useState(0)

    // TODO: We will get current study from context in the future. For now, we use the first one.
    const currentStudy = study[0]
    const [patient, setParticipant] = useState<Novoic.API.Participant>()
    const [tests, setTests] = useState<unknown[]>([])
    const externalId = patient?.studies?.find(
        (study) => study.study_id === currentStudy?.id
    )?.external_id

    const list = useParticipantTestsList(patientId, fetchIdx)
    const canCreateTest =
        list.find(
            (test) =>
                ['complete', 'not started', 'assigned', 'incomplete'].indexOf(test.status) > -1
        ) === undefined

    async function onParticipantStatusChange(value: string | undefined) {
        api.post('/private/patients/status', [
            { patient_id: patientId, status: value ?? null, study_id: currentStudy?.id },
        ]).then(() => {
            setFetchIdx(fetchIdx + 1)
        })
    }

    async function onCreateTest(patientId: string, studyId: string, language: string) {
        const form = mapPropsToData({
            patientId,
            studyId,
            language,
        })

        try {
            const invite = await createAssignmentInvitation(form)
            setFetchIdx(fetchIdx + 1)
            open({
                content: <ShareAssignment data={invite} />,
            })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (list) {
            setTests(list)
        }
    }, [list])

    useEffect(() => {
        const fetchById = async () => {
            if (!patientId) {
                return
            }

            const data = await getParticipant(patientId)
            setParticipant(data)
        }

        fetchById()
    }, [patientId, fetchIdx])

    if (!patient) {
        return null
    }

    const stage = patient.studies?.find((study) => study.study_id === currentStudy?.id)?.status

    const site = patient.assignment?.site
    const score = patient.assignment?.score

    const summary = [
        {
            title: 'Participant ID',
            value: formatOutput(externalId),
        },
        {
            title: 'Score',
            value: formatScore(score),
        },
        {
            title: 'Site',
            value: site ? site[0].toUpperCase() + site.slice(1) : formatOutput(site),
        },
        {
            title: 'AI advice',
            value: formatRecommendation(patient.assignment?.recommend),
        },
        {
            title: 'Registration date',
            value: formatDate(patient.created_at),
        },
        {
            title: 'Tests conducted',
            value: formatOutput(patient.complete_count),
        },
        {
            title: 'Registered by',
            value: 'Dr. John Doe',
        },
    ]

    const profile = [
        {
            title: 'Biological sex',
            value: patient.gender,
        },
        {
            title: 'Race or Ethnicity',
            value: patient.races.join(','),
        },
        {
            title: 'Date of birth',
            value: formatDOB(patient.birth_date),
        },
        {
            title: 'Location',
            value: patient.location || 'n/a',
        },
        {
            title: 'Native language',
            value: patient.language,
        },
        {
            title: 'Phone number',
            value: patient.phone || 'n/a',
        },
        {
            title: 'Education level',
            value: patient.education_level,
        },
        {
            title: 'Email',
            value: patient.email || 'n/a',
        },
    ]

    return (
        <div className="flex flex-col h-full divide-y divide-gray-200">
            <div className="h-[104px]">
                <div className="flex items-center h-full px-8">
                    <BackIcon
                        onClick={() => history.back()}
                        className="cursor-pointer mr-3 fill-gray-500"
                    />
                    <span className="text-2xl font-semibold mr-3">{patient.name}</span>
                    <span className="text-2xl font-normal text-gray-600 mr-3">{externalId}</span>
                    {patient.assignment?.recommend && (
                        <p className="bg-indigo-50 rounded-[32px] py-2 pl-2 pr-4 flex items-center">
                            <AIBadge className="mr-2" />
                            <span className="text-xs text-indigo-800 font-medium">
                                AI Qualified
                            </span>
                        </p>
                    )}
                </div>
            </div>
            <div className="grid grid-cols-2 divide-x divide-gray-200">
                <div className="px-8 py-8">
                    <h2 className="text-xl font-semibold text-gray-900">Summary</h2>
                    <div className="grid grid-cols-2 gap-y-[20px] mt-6">
                        <ReviewButton value={stage} onChange={onParticipantStatusChange} />
                        {summary.map((item) => (
                            <div key={item.title}>
                                <p className="text-sm font-medium text-gray-900">{item.title}</p>
                                <p className="text-sm font-normal text-gray-600">{item.value}</p>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="px-8 py-8">
                    <h2 className="text-xl font-semibold text-gray-900">Profile</h2>
                    <div className="grid grid-cols-2 gap-y-[20px] mt-6">
                        {profile.map((item) => (
                            <div key={item.title}>
                                <p className="text-sm font-medium text-gray-900">{item.title}</p>
                                <p className="text-sm font-normal text-gray-600">{item.value}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {!tests.length && (
                <ParticipantCardEmpty
                    onCreateTest={() => onCreateTest(patient.id, currentStudy.id, patient.language)}
                />
            )}

            {tests.length && (
                <ParticipantCardTests
                    tests={tests}
                    canCreateTest={canCreateTest}
                    onCreateTest={() => onCreateTest(patient.id, currentStudy.id, patient.language)}
                />
            )}
        </div>
    )
}
