.transition_enter {
    opacity: 0;
    transform: translateY(-24px);
}

.transition_enter_active {
    opacity: 1;
    transform: translateY(0px);
    transition: all 300ms;
}

.transition_exit {
    opacity: 1;
    transform: translateY(0px);
}

.transition_exit_active {
    opacity: 0;
    transform: translateY(-24px);
    transition: all 300ms;
}

.modal {
    @apply relative flex gap-4 p-8 flex-col self-center bg-white rounded-lg;
    box-shadow: 0px 0px 1px rgba(49, 46, 129, 0.32), 0px 6px 8px -2px rgba(49, 46, 129, 0.02),
        0px 16px 64px -8px rgba(49, 46, 129, 0.18);
    animation: modalIn 300ms ease-out;
}

.modal_exit {
    animation: modalOut 300ms ease-out;
}

.backdrop {
    @apply top-0 left-0 right-0 -bottom-6 fixed flex justify-center bg-gray-900 bg-opacity-60 z-20;
    animation: fadeIn 300ms ease-out;
}

.backdrop_exit {
    animation: fadeOut 300ms ease-out;
}

.button_container {
    @apply mt-2 flex flex-wrap flex-row-reverse items-end justify-center grow gap-2;
}

.button_container button {
    @apply grow;
}

@media only screen and (min-width: 768px) {
    .button_container button {
        @apply grow;
    }

    .button_container {
        @apply flex-nowrap;
    }
}
