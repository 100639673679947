import { StudyContext } from '../contexts/StudyContext'
import { ModalAPIContext } from './modals/Context'
import { ProtectedRoot } from './ProtectedRoot'
import { SideMenu } from './SideMenu'
import { ToastAPIContext } from './toasts/ToastContext'

export function Layout() {
    return (
        <ToastAPIContext>
            <StudyContext>
                <ModalAPIContext>
                    <div className="flex w-full h-full min-h-screen">
                        <SideMenu />
                        <section className="w-full ml-64">
                            <ProtectedRoot />
                        </section>
                    </div>
                </ModalAPIContext>
            </StudyContext>
        </ToastAPIContext>
    )
}
