import TestsIcon from '../assets/tests.svg'
import PlusIcon from '../assets/plus.svg'
import { PrimaryButton } from './elements/PrimaryButton'

interface ParticipantCardEmptyProps {
    onCreateTest: () => void
}

export function ParticipantCardEmpty({ onCreateTest }: ParticipantCardEmptyProps) {
    return (
        <div className="flex flex-col grow justify-center px-8 py-8">
            <div className="flex self-center flex-col items-center">
                <div className="flex justify-center items-center w-16 h-16 rounded-full bg-indigo-50 mb-4">
                    <TestsIcon />
                </div>
                <h2 className="mb-3 text-gray-900 text-xl font-semibold">Tests will appear here</h2>
                <p className="mb-6 text-base text-gray-600">
                    Assign new Test by pressing the blue button below
                </p>
                <PrimaryButton onClick={onCreateTest}>
                    <PlusIcon className="mr-2 fill-white" />
                    Assign Test
                </PrimaryButton>
            </div>
        </div>
    )
}
