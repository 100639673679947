import { useEffect, useState } from 'react'
import { listAssignmentInvitations } from '../api/listAssignmentInvitations'
import { listAssignments } from '../api/listAssignments'

export function useParticipantTestsList(patientId: string, fetchIdx: number) {
    const [invites, setInvites] = useState<Novoic.API.AssignmentInvitation[]>([])
    const [assignments, setAssignments] = useState<Novoic.API.Assignment[]>([])
    const [list, setList] = useState<Novoic.ParticipantTestListItem[]>([])

    useEffect(() => {
        // Clear the list when the patient changes
        setInvites([])
        setAssignments([])

        if (!patientId) {
            return
        }

        Promise.all([
            listAssignmentInvitations('patients=' + patientId),
            listAssignments('patient=' + patientId),
        ]).then(([invites, assignments]) => {
            setInvites(invites)
            setAssignments(assignments)
        })
    }, [patientId, fetchIdx])

    useEffect(() => {
        const items: Novoic.ParticipantTestListItem[] = []

        if (!invites || !assignments) {
            return
        }

        const inviteIds = invites.map((i) => i.id)
        const assignmentInviteIds = assignments.map((a) => a.assignment_invitation_id)

        invites.forEach((invite) => {
            if (!assignmentInviteIds.includes(invite.id)) {
                items.push({
                    id: invite.id,
                    battery: invite.battery.name,
                    status: 'not started',
                    score: undefined,
                    qc: undefined,
                    completed: undefined,
                    assigned: invite.created_at,
                    duration: undefined,
                    device: undefined,
                    assignmentId: undefined,
                    invite,
                })
            }
        })

        assignments.forEach((assignment) => {
            if (inviteIds.includes(assignment.assignment_invitation_id)) {
                items.push({
                    id: assignment.id,
                    battery: assignment.battery.name,
                    status: assignment.status,
                    score: assignment.scores?.total?.score,
                    scoreLevel: assignment.scores?.total?.level,
                    qc: assignment.qc_pass,
                    completed: assignment.fact_end_time,
                    assigned: assignment.start_time,
                    duration: assignment.fact_duration,
                    device: assignment.device_type,
                    assignmentId: assignment.id,
                })
            }
        })

        setList(items.sort((a, b) => (a.assigned > b.assigned ? -1 : 1)))
    }, [invites, assignments])

    return list
}
