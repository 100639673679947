import { createContext, PropsWithChildren, useEffect, useState } from 'react'
import { useStudy } from '../hooks/useStudy'

export const StudyCtx = createContext<Novoic.API.Study[]>([])

export function StudyContext({ children }: PropsWithChildren) {
    const studyAPI = useStudy()
    const [studies, setStudies] = useState<Novoic.API.Study[]>([])

    useEffect(() => {
        studyAPI.list().then((items) => {
            setStudies(items)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (studies.length === 0) {
        return null
    }

    return <StudyCtx.Provider value={studies}>{children}</StudyCtx.Provider>
}
