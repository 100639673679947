import { useState } from 'react'
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'

interface LocationInputProps {
    onSelect: (address: string, placeId: string, latLng: unknown) => void
}

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

const defaultInputClassNames =
    'w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md'

export function LocationInput({ onSelect }: LocationInputProps) {
    const [address, setAddress] = useState('')

    const handleSelect = async (selectedAddress: string) => {
        setAddress(selectedAddress)
        try {
            const results = await geocodeByAddress(selectedAddress)
            const latLng = await getLatLng(results[0])
            onSelect(selectedAddress, results[0].place_id, latLng)
        } catch (error) {
            console.error('Error fetching geocode data:', error)
        }
    }

    return (
        <PlacesAutocomplete value={address} onChange={setAddress} onSelect={handleSelect}>
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div className="relative">
                    <input
                        {...getInputProps({
                            className: defaultInputClassNames,
                            placeholder: 'Participant’s ZIP code',
                        })}
                    />
                    {suggestions.length ? (
                        <div className="absolute w-full bg-white border border-gray-300 rounded-md mt-1 z-10">
                            {loading && <div className="p-2">Loading...</div>}
                            {suggestions.map((suggestion) => {
                                const className = classNames(
                                    defaultInputClassNames,
                                    suggestion.active
                                        ? 'p-2 hover:bg-gray-200 cursor-pointer bg-gray-100'
                                        : 'p-2 hover:bg-gray-200 cursor-pointer'
                                )

                                return (
                                    <div
                                        key={suggestion.placeId}
                                        {...getSuggestionItemProps(suggestion, { className })}
                                    >
                                        {suggestion.description}
                                    </div>
                                )
                            })}
                        </div>
                    ) : null}
                </div>
            )}
        </PlacesAutocomplete>
    )
}
