import { api } from '../api'

export async function getParticipant(
    patientId: string
): Promise<Novoic.API.Participant & { complete_count: number }> {
    const { data } = await api.get<Novoic.API.Participant>('/private/patients/' + patientId)
    const { data: complete } = await api.get(
        '/private/assignments/count?status=complete&patient=' + patientId
    )
    return {
        ...data,
        complete_count: complete.count,
    }
}
