import { XMarkIcon } from '@heroicons/react/24/outline'
import { AxiosError } from 'axios'
import { useContext, useState } from 'react'
import { api } from '../../../api'
import { StudyCtx } from '../../../contexts/StudyContext'
import { useModal } from '../../../hooks/useModal'
import { DateInput } from '../../DateInput'
import Dropdown from '../../Dropdown'
import { PrimaryButton } from '../../elements/PrimaryButton'
import EthnicitySelector from '../../EthnicitySelector'
import { LocationInput } from '../../LocationInput'
import { PhoneInputComponent } from '../../PhoneInput'
import * as data from './newParticipant.json'

interface Payload {
    study_id: string
    external_id: string
    birth_date: string
    education_level: string
    email?: string
    gender: string
    language: string
    location?: string
    name?: string
    phone?: string
    races: string[]
}

interface ValidationError {
    code: number
    fields: Record<string, string>
    message: string
}

export function NewParticipant() {
    const [, close] = useModal()
    const studies = useContext(StudyCtx)
    const [errors, setErrors] = useState<Record<string, Error>>({})
    const [form, updateForm] = useState<Partial<Payload>>({
        study_id: studies[0]?.id,
        external_id: '',
        birth_date: '',
        education_level: '',
        email: '',
        gender: '',
        language: '',
        location: '',
        name: '',
        phone: '',
        races: [],
    })
    const [validationErrors, setValidationErrors] = useState<Record<string, string>>({})

    function onChange(name: string, value: unknown) {
        updateForm({ ...form, [name]: value })
        setValidationErrors({ ...validationErrors, [name]: undefined })
    }

    function isValidForm() {
        const requiredFields = [
            'external_id',
            'gender',
            'races',
            'language',
            'education_level',
            'birth_date',
        ] as (keyof Payload)[]
        let valid = true
        const newValidationErrors = { ...validationErrors }

        requiredFields.forEach((field) => {
            if (!form[field] || form[field].length === 0) {
                newValidationErrors[field] = 'Required'
                valid = false
            }
        })

        setValidationErrors(newValidationErrors)
        return valid
    }

    async function submit(form: Partial<Payload>) {
        if (!isValidForm()) return

        const data: Record<string, unknown> = {}
        Object.entries(form).forEach(([key, value]) => {
            if (!value) {
                return
            }
            if (key === 'phone') {
                value = (value as string).replace(/\s/g, '')
            }
            data[key] = value
        })

        try {
            await api.post('/private/patients', data)
            close()
        } catch (error: unknown) {
            const { data } = (error as AxiosError<ValidationError>).response
            const acc: Record<string, Error> = {}

            Object.entries(data.fields).forEach(([key, value]) => {
                acc[key] = Error(value)
            })

            setErrors(acc)
        }
    }

    return (
        <>
            <div className="flex justify-between items-center gap-4">
                <h2 className="block font-semibold text-xl text-gray-900">Create Participant</h2>
                <button className="w-6 h-6" onClick={close}>
                    <XMarkIcon />
                </button>
            </div>
            <div className="gap-x-4 w-[536px]">
                <div className="grid grid-cols-2 gap-x-4 gap-y-6 mb-8">
                    <div>
                        <div className="mb-1">
                            <label
                                htmlFor="external_id"
                                className="block text-sm font-medium text-gray-700"
                            >
                                Participant ID
                            </label>
                        </div>
                        <input
                            type={'text'}
                            id={'external_id'}
                            placeholder={'e.g. 123-A-45678'}
                            name={'external_id'}
                            required
                            onChange={(e) => {
                                onChange('external_id', e.target.value)
                            }}
                            className={`w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md ${
                                validationErrors.external_id ? 'border-red-600' : ''
                            }`}
                        />
                        {validationErrors.external_id && (
                            <p className="text-sm text-red-600">Required</p>
                        )}
                    </div>
                    <div>
                        <div className="mb-1">
                            <label
                                htmlFor="name"
                                className="block text-sm font-medium text-gray-700"
                            >
                                Full name{' '}
                                <span className="font-normal text-gray-500">(optional)</span>
                            </label>
                        </div>
                        <input
                            type={'text'}
                            id={'name'}
                            placeholder={'e.g. John Doe'}
                            name={'name'}
                            onChange={(e) => {
                                onChange('name', e.target.value)
                            }}
                            className="w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                        />
                    </div>
                    <div className="flex flex-col">
                        <Dropdown
                            label="Biological sex"
                            value={form.gender}
                            onChange={(value) => {
                                onChange('gender', value)
                            }}
                            placeholder="Choose biological sex"
                            options={data.genderOptions}
                            error={validationErrors.gender}
                        />
                    </div>
                    <div className="flex flex-col">
                        <DateInput
                            name="dob"
                            id="dob"
                            label="Date of birth"
                            value={form.birth_date}
                            placeholder="e.g. 01/01/1968"
                            error={validationErrors.birth_date}
                            onChange={(value) => {
                                onChange('birth_date', value)
                            }}
                        />
                    </div>
                    <div className="flex flex-col">
                        <Dropdown
                            label="Native language"
                            value={form.language}
                            onChange={(value) => {
                                onChange('language', value)
                            }}
                            placeholder="Choose native language"
                            options={data.languageOptions}
                            error={validationErrors.language}
                        />
                    </div>
                    <div className="flex flex-col">
                        <Dropdown
                            label="Maximum educational level"
                            value={form.education_level}
                            onChange={(value) => {
                                onChange('education_level', value)
                            }}
                            placeholder="Choose educational level"
                            options={data.educationOptions}
                            error={validationErrors.education_level}
                        />
                    </div>

                    <div className="col-span-2">
                        <EthnicitySelector
                            options={data.races}
                            value={form.races}
                            onChange={(races) => onChange('races', races)}
                            error={validationErrors.races}
                        />
                    </div>

                    <div className="col-span-2">
                        <div className="mb-1">
                            <label htmlFor="location" className="text-sm font-medium text-gray-700">
                                Location within US{' '}
                                <span className="font-normal text-gray-500">(optional)</span>
                            </label>
                        </div>
                        <LocationInput onSelect={(address) => onChange('location', address)} />
                    </div>

                    <div>
                        <div className="mb-1">
                            <label htmlFor="email" className="text-sm font-medium text-gray-700">
                                Email <span className="font-normal text-gray-500">(optional)</span>
                            </label>
                        </div>
                        <input
                            type={'email'}
                            id={'email'}
                            placeholder={'e.g. arnold.patient@mail.com'}
                            onChange={(e) => {
                                onChange('email', e.target.value)
                            }}
                            name={'email'}
                            className="w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                        />
                    </div>

                    <div>
                        <div className="mb-1">
                            <label htmlFor="phone" className="text-sm font-medium text-gray-700">
                                Phone number{' '}
                                <span className="font-normal text-gray-500">(optional)</span>
                            </label>
                        </div>
                        <PhoneInputComponent onChange={(value) => onChange('phone', value)} />
                    </div>
                </div>

                <div className="flex col-span-2 justify-end gap-2">
                    <button
                        type="button"
                        onClick={() => close()}
                        className="inline-flex w-auto items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2.5 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 transition-all"
                    >
                        Cancel
                    </button>
                    <PrimaryButton onClick={() => submit(form)}>Create Participant</PrimaryButton>
                </div>
            </div>
        </>
    )
}
