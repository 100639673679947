import { Outlet } from 'react-router-dom'
import { useAssets } from '../hooks/useAssets'

export function PublicRoot() {
    const areAssetsReady = useAssets()

    if (!areAssetsReady) {
        return null
    }

    return <Outlet />
}
