import { DateTime, Interval } from 'luxon'

const statusMapStyle: Record<Novoic.AssignmentStatus, string> = {
    empty: 'bg-indigo-100 text-indigo-800',
    overdue: 'bg-yellow-100 text-yellow-800',
    'not started': 'bg-indigo-100 text-indigo-800',
    incomplete: 'bg-indigo-100 text-indigo-800',
    assigned: 'bg-indigo-100 text-indigo-800',
    complete: 'bg-indigo-500 text-white',
    cancelled: 'bg-red-100 text-red-800',
    expired: 'bg-red-100 text-red-800',
}

const statusMap: Record<string, string> = {
    empty: 'No test',
    'not started': 'Pending',
    incomplete: 'Pending',
    assigned: 'Pending',
    complete: 'Ready',
    cancelled: 'Failed',
    expired: 'Failed',
}

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

function getLabel(status: Novoic.AssignmentStatus, title: string, desc?: string) {
    return (
        <>
            <span
                className={classNames(
                    'text-xs font-medium rounded-[10px] py-0.5 px-2',
                    statusMapStyle[status]
                )}
            >
                {title || statusMap[status]}
            </span>
            {desc && <span className="pl-1 text-sm font-medium">{desc}</span>}
        </>
    )
}

export function formatStatus(
    status: Novoic.AssignmentStatus,
    hasInvite: boolean,
    score: number,
    level: string,
    timestamp: string
): JSX.Element {
    if (!status) {
        status = 'not started'
        if (!hasInvite) {
            status = 'empty'
        }
    }

    let formatted = statusMap[status]
    const dt = DateTime.fromISO(timestamp)
    let desc = formatted
    let label = getLabel(status, formatted)

    if (formatted === 'Pending') {
        desc = 'Assigned'
        if (Interval.fromDateTimes(dt, DateTime.now()).length('hours') > 48) {
            formatted = 'Overdue'
            status = 'overdue'
            label = getLabel(status, formatted)
        }
    } else if (formatted === 'Ready') {
        label = getLabel(status, `${Math.round(score)}`, level)
        desc = 'Completed'
    }

    return (
        <div className="flex flex-col gap-y-1">
            <div>{label}</div>
            {timestamp && (
                <span className="text-gray-600 text-xs font-normal">{`${desc} ${dt.toRelative()}`}</span>
            )}
        </div>
    )
}
