import { ButtonHTMLAttributes, PropsWithChildren } from 'react'

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

const baseState =
    'inline-flex w-auto items-center justify-center rounded-md px-4 py-2.5 text-sm font-medium shadow-sm transition-all'
const hoverState = 'hover:bg-indigo-800'
const focusState = 'focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
const primaryState = 'bg-indigo-600 text-white'
const disabledState = 'bg-gray-200 text-gray-500 cursor-not-allowed'

export function PrimaryButton(props: PropsWithChildren<ButtonHTMLAttributes<HTMLButtonElement>>) {
    return (
        <button
            onClick={props.onClick}
            type="button"
            disabled={props.disabled}
            className={classNames(
                baseState,
                props.disabled ? disabledState : classNames(primaryState, hoverState, focusState)
            )}
        >
            {props.children}
        </button>
    )
}
