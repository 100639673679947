import { createAsyncThunk, createSlice, SliceCaseReducers } from '@reduxjs/toolkit'
import { listAssignmentInvitations } from '../api/listAssignmentInvitations'

interface AssignmentInviteState {
    entries: Novoic.API.AssignmentInvitation[]
    loading: boolean
}

export const assignmentInviteSlice = createSlice<
    AssignmentInviteState,
    SliceCaseReducers<AssignmentInviteState>
>({
    name: 'assignmentInvitations',
    initialState: {
        entries: [],
        loading: true,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchAssignmentInvitations.pending, (state) => {
            if (state.entries.length === 0) {
                state.loading = true
            }
        })
        builder.addCase(fetchAssignmentInvitations.fulfilled, (state, action) => {
            state.entries = action.payload
            state.loading = false
        })
        builder.addCase(fetchAssignmentInvitations.rejected, (state) => {
            state.loading = false
        })
    },
})

export const fetchAssignmentInvitations = createAsyncThunk(
    'assignmentInvitations/fetchAssignmentInvitations',
    async (query?: string): Promise<Novoic.API.AssignmentInvitation[]> => {
        const assignmentInvitations = await listAssignmentInvitations(query)
        return assignmentInvitations
    }
)
