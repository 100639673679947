import { api } from '../api'

export async function getParticipants(search?: string): Promise<Novoic.API.Participant[]> {
    let participantURL = '/private/patients'

    if (search) {
        participantURL += '?' + search
    }

    const { data: participants } = await api.get<Novoic.API.Participant[]>(participantURL)

    return participants
}
