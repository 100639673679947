import { Navigate } from 'react-router-dom'
import { getParticipants } from '../api/getParticipants'
import { ParticipantCard } from '../components/ParticipantCard'
import { ParticipantList } from '../components/ParticipantList'
import { filters } from '../config'
import { AppDispatch } from '../store'
import { generateLoader } from '../utils/generateLoader'
import { fetchCombinedParticipantList } from '../features/combinedParticipantListSlice'

export function getParticipantRoutes(dispatch: AppDispatch) {
    return [
        {
            path: 'review',
            element: <ParticipantList />,
            loader: generateLoader(
                (query: string) => dispatch(fetchCombinedParticipantList(query)),
                filters.participants.review
            ),
        },
        {
            path: 'bookmarked',
            element: <ParticipantList />,
            loader: generateLoader(
                (query: string) => dispatch(fetchCombinedParticipantList(query)),
                filters.participants.bookmarked
            ),
        },
        {
            path: 'selected',
            element: <ParticipantList />,
            loader: generateLoader(
                (query: string) => dispatch(fetchCombinedParticipantList(query)),
                filters.participants.selected
            ),
        },
        {
            path: 'excluded',
            element: <ParticipantList />,
            loader: generateLoader(
                (query: string) => dispatch(fetchCombinedParticipantList(query)),
                filters.participants.excluded
            ),
        },
        {
            path: ':filter/:patientId',
            element: <ParticipantCard />,
            loader: generateLoader(getParticipants),
        },
        {
            index: true,
            element: <Navigate to="review" replace />,
        },
    ]
}
