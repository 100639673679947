import { ProtectedRoute } from './ProtectedRoute'
import { Outlet } from 'react-router-dom'

export function ProtectedRoot() {
    return (
        <ProtectedRoute>
            <Outlet />
        </ProtectedRoute>
    )
}
