import { PaperAirplaneIcon, PlayCircleIcon } from '@heroicons/react/20/solid'
import { LinkIcon, XMarkIcon } from '@heroicons/react/24/outline'
import CopyLinkIcon from '../../../assets/copy-link.svg'
import QRIcon from '../../../assets/qr.svg'
import { useModal } from '../../../hooks/useModal'
import { useToast } from '../../../hooks/useToast'
import { QRCode } from './QRCode'

interface ShareAssignmentProps {
    data: Novoic.API.AssignmentInvitation
}

export function ShareAssignment(props: ShareAssignmentProps) {
    const [open, close] = useModal()
    const [openToast] = useToast()

    function onCopyLink() {
        navigator.clipboard.writeText(props.data.link)
        openToast({
            title: 'Link copied!',
            icon: <LinkIcon className="h-6 w-6 text-indigo-400" aria-hidden="true" />,
        })
    }

    function onOpenQR() {
        open({
            content: <QRCode invite={props.data} />,
        })
    }

    function onOpenLink() {
        window.open(props.data.link, '_blank')
    }

    return (
        <div className="w-[416px]">
            <div className="flex justify-between items-center gap-4 mb-6">
                <div className="flex">
                    <h2 className="block font-semibold text-xl text-gray-900">Test details</h2>
                </div>
                <button className="w-6 h-6" onClick={close}>
                    <XMarkIcon />
                </button>
            </div>
            <div>
                <div className="mb-4">
                    <p className="font-medium text-base text-gray-900 mb-2">Share link</p>
                    <span className="text-sm font-normal text-gray-500">
                        Send a test link to the participant’s email or messenger for them to take
                        the test on their device
                    </span>
                </div>
                <div className="flex mb-4">
                    <input
                        type="email"
                        className="mr-2 flex flex-grow rounded-md border border-gray-300 bg-white px-4 py-2 text-sm text-gray-500 shadow-sm disabled:bg-gray-100"
                        value={props.data.link}
                        readOnly
                        disabled
                    />
                </div>

                <div className="flex gap-x-2 mb-8">
                    <button
                        type="button"
                        onClick={onCopyLink}
                        className="inline-flex flex-grow items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2.5 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 transition-all"
                    >
                        <CopyLinkIcon className="mr-2" />
                        Copy Link
                    </button>

                    <button
                        type="button"
                        onClick={onOpenQR}
                        className="inline-flex flex-grow items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2.5 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 transition-all"
                    >
                        <QRIcon className="mr-2" />
                        Generate QR
                    </button>
                </div>

                <div className="relative mb-8 mt-8">
                    <div className="flex absolute w-full border-b border-gray-200"></div>
                    <div className="relative items-center justify-center flex">
                        <span className="absolute bg-white text-gray-500 p-2 text-xs self-center">
                            or
                        </span>
                    </div>
                </div>

                <div>
                    <div className="mb-4">
                        <p className="font-medium text-base text-gray-900 mb-2">Launch test</p>
                        <span className="text-sm font-normal text-gray-500">
                            Launch the test on this device immediately
                        </span>
                    </div>

                    <button
                        onClick={onOpenLink}
                        className="inline-flex w-full items-center justify-center rounded-md bg-indigo-600 px-4 py-2.5 text-sm font-medium text-white shadow-sm hover:bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 transition-all"
                    >
                        <PlayCircleIcon className="w-5 h-5 mr-2" />
                        <span>Launch Test</span>
                    </button>
                </div>
            </div>
        </div>
    )
}
