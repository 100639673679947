/**
 * Format duration from ms to the local "hh:mm:ss"-like string
 * @param duration number
 * @param format string
 * @returns string
 */
export function formatDuration(duration: number, format = 'en-GB'): JSX.Element {
    if (duration === undefined) {
        return <span className="text-gray-400">n/a</span>
    }

    const dateFormatter = new Intl.DateTimeFormat(format, {
        timeZone: 'Etc/UTC',
        hour12: false,
        minute: '2-digit',
        second: '2-digit',
    })

    const time = dateFormatter.format(new Date(duration * 1000))
    return <span>{time}</span>
}
