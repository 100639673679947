import { createSlice, createAsyncThunk, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit'
import { getParticipantCount } from '../api/getParticipantCount'
import { filters } from '../config'
import { updateParticipantStatus } from './combinedParticipantListSlice'

interface MenuItem {
    title: 'Awaiting review' | 'Not qualified' | 'Invited' | 'Qualified'
    count: number
    query: string
    uri: string
}

interface MenuState {
    active: string
    participants: {
        items: MenuItem[]
    }
}

export const fetchParticipantCounters = createAsyncThunk(
    'menu/fetchParticipantCounters',
    async (): Promise<Partial<MenuItem>[]> => {
        const [review, excluded, bookmarked, selected] = await Promise.allSettled([
            getParticipantCount(filters.participants.review),
            getParticipantCount(filters.participants.excluded),
            getParticipantCount(filters.participants.bookmarked),
            getParticipantCount(filters.participants.selected),
        ])

        if (review.status === 'rejected') {
            throw new Error(review.reason)
        }

        if (excluded.status === 'rejected') {
            throw new Error(excluded.reason)
        }

        if (bookmarked.status === 'rejected') {
            throw new Error(bookmarked.reason)
        }

        if (selected.status === 'rejected') {
            throw new Error(selected.reason)
        }

        return [
            {
                title: 'Awaiting review',
                count: review.value,
            },
            {
                title: 'Not qualified',
                count: excluded.value,
            },
            {
                title: 'Qualified',
                count: selected.value,
            },
            {
                title: 'Invited',
                count: bookmarked.value,
            },
        ]
    }
)

export const menuSlice = createSlice<MenuState, SliceCaseReducers<MenuState>>({
    name: 'menu',
    initialState: {
        active: '',
        participants: {
            items: [
                {
                    title: 'Awaiting review',
                    count: 0,
                    query: filters.participants.review,
                    uri: '/app/participants/review',
                },
                {
                    title: 'Not qualified',
                    count: 0,
                    query: filters.participants.excluded,
                    uri: '/app/participants/excluded',
                },
                {
                    title: 'Qualified',
                    count: 0,
                    query: filters.participants.selected,
                    uri: '/app/participants/selected',
                },
                {
                    title: 'Invited',
                    count: 0,
                    query: filters.participants.bookmarked,
                    uri: '/app/participants/bookmarked',
                },
            ],
        },
    },
    reducers: {
        setActive: (state, action: PayloadAction<string>) => {
            state.active = action.payload
        },
    },
    extraReducers(builder) {
        builder.addCase(fetchParticipantCounters.fulfilled, (state, action) => {
            state.participants.items = state.participants.items.map((item) => {
                const counter = action.payload.find((counter) => counter.title === item.title)
                return {
                    ...item,
                    count: counter?.count || 0,
                }
            })
        })

        builder.addCase(updateParticipantStatus.fulfilled, (state, action) => {
            const from = state.participants.items.find((item) => item.uri === state.active)
            if (!from) {
                throw new Error('No "from" menu item')
            }
            from.count -= action.payload.participants.length

            const statusToUri: Record<string, string> = {
                review: '/app/participants/review',
                excluded: '/app/participants/excluded',
                bookmarked: '/app/participants/bookmarked',
                selected: '/app/participants/selected',
            }

            action.payload.participants.forEach((participant) => {
                const uri = statusToUri[participant.status]
                const to = state.participants.items.find((item) => item.uri === uri)

                if (!to) {
                    throw new Error('No "to" menu item')
                }
                to.count += 1
            })
        })
    },
})

export const { setActive } = menuSlice.actions
