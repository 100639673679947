import { PropsWithChildren, SVGProps } from 'react'
import { NavLink } from 'react-router-dom'
import * as css from './MenuItem.module.scss'

interface MenuItemProps extends PropsWithChildren {
    to: string
    isActive: boolean
    icon: React.ComponentType<SVGProps<unknown>>
    title: string
}

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

export function MenuItem(props: MenuItemProps) {
    return (
        <NavLink
            to={props.to}
            className={({ isActive }) => classNames(css.item, isActive ? css.active : '')}
        >
            <div className="flex items-center">
                <props.icon className={css.icon} />
                <span className={css.title}>{props.title}</span>
            </div>
        </NavLink>
    )
}
