export function useLocalStorage(): [
    <T>(key: string, value: T) => void,
    <T>(key: string) => T,
    (key: string) => void
] {
    function set<T>(key: string, value: T) {
        let v
        if (typeof value !== 'string') {
            v = JSON.stringify(value)
        } else {
            v = value
        }
        localStorage.setItem(key, v)
    }

    function get<T>(key: string): T | undefined {
        const v: string = localStorage.getItem(key)
        try {
            return JSON.parse(v)
        } catch (e: unknown) {
            return v as T
        }
    }

    function remove(key: string): void {
        localStorage.removeItem(key)
    }

    return [set, get, remove]
}
