import { NavLink, useMatches } from 'react-router-dom'

import { UserGroupIcon } from '@heroicons/react/24/outline'
import PlusIcon from '../../assets/plus.svg'
import { MenuItem } from './MenuItem'
import { NewParticipant } from '../modals/content/NewParticipant'
import { useModal } from '../../hooks/useModal'
import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux'
import { fetchParticipantCounters, setActive } from '../../features/menuSlice'

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

export function ParticipantsMenuItem() {
    const [open] = useModal()
    const matches = useMatches()
    const dispatch = useAppDispatch()
    const active = useAppSelector((state) => state.menu.active)
    const menu = useAppSelector((state) => state.menu.participants.items)

    const fullUrl = matches[matches.length - 1].pathname

    useEffect(() => {
        dispatch(fetchParticipantCounters())
    }, [dispatch])

    useEffect(() => {
        const currentActive = menu.find((item) => fullUrl.startsWith(item.uri))?.uri
        if (currentActive) {
            dispatch(setActive(currentActive))
        }
    }, [dispatch, fullUrl, menu])

    const activeIdx = menu.findIndex((item) => item.uri === active)
    const offset = Math.max(activeIdx, 0)
    const areParticipantsExpanded = fullUrl.startsWith('/app/participants')

    function onNavigate(title: string) {
        dispatch(setActive(title))
    }

    return (
        <>
            <div className="relative flex flex-col">
                <MenuItem
                    isActive={areParticipantsExpanded}
                    icon={UserGroupIcon}
                    title="Participants"
                    to="participants"
                />

                {areParticipantsExpanded && (
                    <button
                        onClick={() => open({ content: <NewParticipant /> })}
                        className="absolute w-8 h-8 transition-all bg-indigo-100 hover:bg-indigo-200 flex justify-center items-center rounded-md p-1 right-2 top-2"
                    >
                        <PlusIcon className="w-5 h-5 fill-indigo-500" />
                    </button>
                )}
            </div>

            {areParticipantsExpanded && (
                <div className="flex pl-4 items-start">
                    <div className="w-[2px] h-[calc(100%_-_16px)] mt-2 bg-gray-100">
                        <div
                            className="w-[2px] h-6 bg-indigo-500 transition-transform"
                            style={{ transform: `translateY(${offset * 40}px` }}
                        />
                    </div>

                    <div className="pl-8 flex grow">
                        <ul className="w-full text-sm font-medium">
                            {menu.map(({ title, uri, count }) => (
                                <NavLink
                                    key={uri}
                                    to={uri}
                                    onClick={() => onNavigate(uri)}
                                    className={({ isActive }) =>
                                        classNames(
                                            'hover:text-gray-900',
                                            isActive ? 'text-gray-900' : 'text-gray-600'
                                        )
                                    }
                                >
                                    <li className="py-2.5 flex justify-between items-center">
                                        <span>{title}</span>
                                        <span className="pr-3 font-medium text-xs">{count}</span>
                                    </li>
                                </NavLink>
                            ))}
                        </ul>
                    </div>
                </div>
            )}
        </>
    )
}
