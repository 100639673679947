export interface Config {
    api: string
    s3: {
        buckets: Record<'static', string>
    }
}

const config: Config = {
    api: process.env.CRM_HOST,
    s3: {
        buckets: {
            static: 'https://novoic-static.s3.us-west-2.amazonaws.com',
        },
    },
}

export default config

export const filters = {
    tests: {
        assigned: 'status=assigned&has_review=false',
        incomplete: 'status=incomplete&status=timeout&status=cancelled&has_review=false',
        review: 'status=complete&has_review=false',
        bookmarked: 'review_decision=bookmarked',
        selected: 'review_decision=selected',
        excluded: 'review_decision=excluded',
    },
    participants: {
        review: 'has_status=false',
        selected: 'status=selected',
        bookmarked: 'status=bookmarked',
        excluded: 'status=excluded',
    },
}
