import { Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'

interface DropdownOption {
    title: string
    value: unknown
}

interface DropdownProps {
    placeholder?: string
    label: string
    value?: unknown
    onChange: (...args: unknown[]) => void
    options: DropdownOption[]
    error?: string
}

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

export default function Dropdown(props: DropdownProps) {
    const { label, options, placeholder, value, onChange, error } = props
    const current = options.find((o) => o.value === value)

    return (
        <Listbox
            as="div"
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            className={classNames(
                'relative inline-block text-left min-w-fit',
                error ? 'border-red-600' : 'border-gray-300'
            )}
            value={value}
            onChange={onChange}
        >
            <div className="mb-1">
                <Listbox.Label className="font-medium text-sm text-gray-700">{label}</Listbox.Label>
            </div>
            <Listbox.Button
                className={classNames(
                    'inline-flex w-full justify-between rounded-md border bg-white px-4 py-2 text-sm text-gray-500 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100',
                    current?.title ? 'font-medium' : 'font-regular',
                    error ? 'border-red-600' : 'border-gray-300'
                )}
            >
                {current?.title || placeholder}
                <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
            </Listbox.Button>

            {error && (
                <div className="mt-2 text-sm text-red-600">
                    <span>{error}</span>
                </div>
            )}

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Listbox.Options className="absolute left-0 z-10 mt-2 w-full origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                        {options.map((option) => {
                            return (
                                <Listbox.Option key={option.title} value={option.value}>
                                    {({ active }) => (
                                        <span
                                            className={classNames(
                                                active
                                                    ? 'bg-gray-100 text-gray-900'
                                                    : 'text-gray-700',
                                                'block w-full text-left px-4 py-2 text-sm'
                                            )}
                                        >
                                            {option.title}
                                        </span>
                                    )}
                                </Listbox.Option>
                            )
                        })}
                    </div>
                </Listbox.Options>
            </Transition>
        </Listbox>
    )
}
