import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import { useState, useContext } from 'react'
import { ParticipantListTable } from './ParticipantListTable'
import { useNavigate } from 'react-router-dom'
import { Pagination } from './Pagination'
import ReviewButton from './ReviewButton'
import { StudyCtx } from '../contexts/StudyContext'
import { ParticipantListEmpty } from './ParticipantListEmpty'
import { NewParticipant } from './modals/content/NewParticipant'
import { useModal } from '../hooks/useModal'
import { useAppDispatch, useAppSelector } from '../hooks/useRedux'
import { updateParticipantStatus } from '../features/combinedParticipantListSlice'

export function ParticipantList() {
    const [open] = useModal()
    const [selectedRows, setSelectedRows] = useState<string[]>([])
    const navigate = useNavigate()
    const study = useContext(StudyCtx)
    const dispatch = useAppDispatch()
    const currentStudy = study[0]

    /**
     * We use the combinedParticipants slice here because we want to display the
     * status of the participant in the list, which is a composite field. It depends
     * on the status of the participant in the study, and existance of the
     * assignment invitation for the participant.
     */
    const participants = useAppSelector((state) => state.combinedParticipants.entries)
    const loading = useAppSelector((state) => state.combinedParticipants.loading)
    const active = useAppSelector((state) => state.menu.active)
    const total = useAppSelector(
        (state) => state.menu.participants.items.find((item) => item.uri === active)?.count || 0
    )

    function handleRowClick(e: Event, userId: string) {
        if (e.target instanceof HTMLInputElement) {
            return
        }
        navigate(userId + location.search)
    }

    async function handleReviewChange(status: string) {
        const payload = selectedRows.map((patientId) => ({
            patient_id: patientId,
            status: status,
            study_id: currentStudy?.id,
        }))

        await dispatch(updateParticipantStatus(payload))
        setSelectedRows([])
    }

    const renderSearchButton = () =>
        selectedRows.length === 0 && (
            <button
                type="button"
                className="inline-flex items-center w-10 h-10 rounded-md border border-gray-300 bg-white px-3 py-3 mr-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
                <MagnifyingGlassIcon className="w-4 h-4" />
            </button>
        )

    const renderReviewButton = () =>
        selectedRows.length > 0 && (
            <ReviewButton
                value={undefined}
                num={selectedRows.length}
                onChange={handleReviewChange}
            />
        )

    if (loading) {
        return null
    }

    return (
        <div className="relative w-full">
            {participants?.length > 0 && (
                <div className="flex flex-wrap fixed top-0 left-[256px] right-0 p-8 bg-white z-20 border-b justify-between">
                    <div className="flex">
                        {renderSearchButton()}
                        {renderReviewButton()}
                    </div>
                </div>
            )}
            <div className="flex flex-wrap mt-[102px] bg-white">
                <div className="w-full">
                    <div className="inline-block min-w-full align-middle">
                        <div className="flex flex-col">
                            {participants?.length > 0 ? (
                                <>
                                    <ParticipantListTable
                                        data={participants}
                                        selectedRows={selectedRows}
                                        onRowSelected={setSelectedRows}
                                        onRowClicked={handleRowClick}
                                    />
                                    <div className="flex w-full h-[74px] items-center bg-white border-t border-t-gray-200">
                                        <Pagination total={total} />
                                    </div>
                                </>
                            ) : (
                                <ParticipantListEmpty
                                    onCreateTest={() => open({ content: <NewParticipant /> })}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
