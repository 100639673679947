import axios, { AxiosError } from 'axios'
import config from '../config'

interface LoginSucceed {
    token: string
    user: Novoic.User
}

function isForbidden(status: number): boolean {
    return status === 403
}

export function useLogin(): (email: string, password: string) => Promise<LoginSucceed> {
    const attemptLogin = async (email: string, password: string) => {
        try {
            const { data } = await axios.post<LoginSucceed>(config.api + '/auth/signin', {
                email,
                password,
            })
            return data
        } catch (e: unknown) {
            const { status } = (e as AxiosError).response
            if (isForbidden(status)) {
                throw new Error('Failed to login')
            }
        }
    }

    return attemptLogin
}
