import { ChangeEvent, useEffect, useState } from 'react'
import { AsYouType } from 'libphonenumber-js'

interface PhoneInputProps {
    onChange: (value: string) => void
    value?: string
}

export function PhoneInputComponent({ onChange, value }: PhoneInputProps) {
    const [formattedValue, setFormattedValue] = useState(value)

    useEffect(() => {
        setFormattedValue(value)
    }, [value])

    const formatPhoneNumber = (input: string) => {
        const asYouType = new AsYouType('US')
        const formatted = asYouType.input(input.startsWith('+1') ? input : `+1${input}`)
        return asYouType.getNumber() ? formatted : input
    }

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const rawValue = e.target.value
        if (rawValue.length > 14) return

        const onlyNumbers = rawValue.replace(/[^+0-9\s()-]*$/g, '')
        const formatted = formatPhoneNumber(onlyNumbers)
        setFormattedValue(formatted)
        onChange(formatted)
    }

    return (
        <input
            type="tel"
            value={formattedValue || ''}
            onChange={handleInputChange}
            className="w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
            placeholder={'+1'}
        />
    )
}
