import { configureStore } from '@reduxjs/toolkit'
import { menuSlice } from './features/menuSlice'
import { participantSlice } from './features/participantSlice'
import { combinedParticipantListSlice } from './features/combinedParticipantListSlice'
import { assignmentInviteSlice } from './features/assignmentInviteSlice'

export const store = configureStore({
    reducer: {
        combinedParticipants: combinedParticipantListSlice.reducer,
        participants: participantSlice.reducer,
        assignmentInvites: assignmentInviteSlice.reducer,
        menu: menuSlice.reducer,
    },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
