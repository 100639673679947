interface EthnicitySelectorProps {
    options: { title: string; value: string }[]
    value?: string[]
    onChange: (value: string[]) => void
    error?: string
}

export default function EthnicitySelector(props: EthnicitySelectorProps) {
    const { options, value = [], onChange, error } = props

    function handleChange(newValue: string) {
        const index = value.indexOf(newValue)
        const newValues = [...value]

        if (index === -1) {
            newValues.push(newValue)
        } else {
            newValues.splice(index, 1)
        }

        onChange(newValues)
    }

    return (
        <>
            <div className="mb-1">
                <span className="text-sm font-medium text-gray-700">
                    Racial/ethnic background{' '}
                    <span className="font-normal text-gray-500">
                        (you can choose multiple options)
                    </span>
                </span>
            </div>
            <div
                className={`grid grid-cols-[120px_minmax(0,1fr)] gap-y-2 bg-gray-50 border rounded-lg py-5 px-4 ${
                    error ? 'border-red-600' : 'border-gray-200'
                }`}
            >
                {options.map((option) => (
                    <div key={option.value} className="space-y-5">
                        <div className="relative flex items-start">
                            <div className="flex h-6 items-center">
                                <input
                                    id={option.value}
                                    name={option.value}
                                    checked={value.includes(option.value)}
                                    type="checkbox"
                                    onChange={() => handleChange(option.value)}
                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                />
                            </div>
                            <div className="ml-3 text-sm leading-6">
                                <label htmlFor={option.value} className="font-medium text-gray-900">
                                    {option.title}
                                </label>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
        </>
    )
}
