import { ModalConfig } from './Context'
import * as css from './Modal.module.css'

Modal.defaultProps = {
    canClose: true,
}

export function Modal(props: ModalConfig) {
    return (
        <div className={css.backdrop}>
            <div className={css.modal}>{props.content}</div>
        </div>
    )
}
