import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import { useSearchParams } from 'react-router-dom'

interface PaginationProps {
    total: number
}

export function Pagination({ total }: PaginationProps) {
    const [searchParams, setSearchParams] = useSearchParams()

    function onPrev() {
        const offset = parseInt(searchParams.get('offset') || '0')
        if (offset === 0) {
            return
        }

        if (offset < 0 || offset - 50 < 0) {
            setSearchParams({ offset: '0' })
        } else {
            const newOffset = offset - 50
            setSearchParams({ offset: newOffset.toString() })
        }
    }

    function onNext() {
        const offset = parseInt(searchParams.get('offset') || '0')

        let newOffset = offset + 50

        if (newOffset >= total) {
            newOffset = total - 50
            if (newOffset < 0 || newOffset < offset) {
                return
            }
        }
        setSearchParams({ offset: newOffset.toString() })
    }

    const offset = parseInt(searchParams.get('offset')) || 0
    const from = offset + 1
    const to = offset + parseInt(searchParams.get('limit') || '50')

    return (
        <>
            <div className="flex ml-8 mr-4">
                <button
                    onClick={onPrev}
                    className="flex items-center rounded-md border border-gray-300 bg-white w-full mr-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                    <ChevronLeftIcon className="w-5 m-2.5 h-5 text-gray-400" />
                </button>
                <button
                    onClick={onNext}
                    className="flex items-center rounded-md border border-gray-300 bg-white w-full text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                    <ChevronRightIcon className="w-5 m-2.5 h-5 text-gray-400" />
                </button>
            </div>
            <div className="flex">
                <span className="font-medium text-xs text-gray-900">
                    {from} - {Math.min(to, total)}
                    <span className="font-regular text-gray-500"> of {total}</span>
                </span>
            </div>
        </>
    )
}
