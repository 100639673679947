import axios, { AxiosError, AxiosResponse } from 'axios'
import config from './config'
import { v4 as uuid } from 'uuid'
import { logout } from './utils/logout'

function isExpired({ status }: AxiosResponse) {
    return status === 403
}

const createAPI = () => {
    const defaultOptions = {
        baseURL: config.api,
        headers: {
            'Content-Type': 'application/json',
            'X-Request-Id': uuid(),
        },
    }

    // Create instance
    const instance = axios.create(defaultOptions)

    // Set the AUTH token for any request
    instance.interceptors.request.use(function (config) {
        const token = localStorage.getItem('token')
        config.headers.Authorization = token ? `Bearer ${token}` : ''
        config.headers['X-Request-Id'] = uuid()
        return config
    })

    instance.interceptors.response.use(
        (config) => config,
        (error: AxiosError) => {
            if (isExpired(error.response)) {
                logout()
            } else {
                throw error
            }
        }
    )

    return instance
}

export const api = createAPI()
