import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { useLayoutEffect, useRef, useState } from 'react'
import AiBadge from '../assets/ai.svg'
import { formatStatus } from '../utils/formatStatus'

const columns = [
    'Participant',
    'Score',
    'Sex',
    'Age',
    'Native language',
    'Education level',
    'Race or Ethnicity',
    'Location',
    'Contacts',
]

interface ParticipantListTableProps {
    data: Novoic.CombinedParticipantItem[]
    selectedRows: string[]
    onRowSelected: (selected: string[]) => void
    onRowClicked: (e: unknown, row: string) => void
}

function formatParticipant(patient_id: string, name: string, recommended: boolean): JSX.Element {
    return (
        <div className="flex flex-col gap-y-1">
            <p className="flex text-sm font-medium">
                {formatOutput(patient_id)}
                {recommended && <AiBadge className="ml-2 w-5 h-5" />}
            </p>
            <p className="text-xs font-regular text-gray-600">{formatOutput(name)}</p>
        </div>
    )
}

function formatContacts(phone: string, email: string): JSX.Element {
    return (
        <div>
            <p>{formatOutput(email)}</p>
            <p>{formatOutput(phone)}</p>
        </div>
    )
}

function formatAge(birth_date: string): number {
    if (!birth_date) {
        return undefined
    }

    const ageDifMs = Date.now() - new Date(birth_date).getTime()
    const ageDate = new Date(ageDifMs)

    return Math.abs(ageDate.getUTCFullYear() - 1970)
}

function formatOutput(input: string | number): JSX.Element {
    return input == undefined ? <span className="text-gray-400">n/a</span> : <span>{input}</span>
}

export function ParticipantListTable({
    data,
    selectedRows,
    onRowSelected,
    onRowClicked,
}: ParticipantListTableProps) {
    const checkbox = useRef<HTMLInputElement>()
    const [checked, setChecked] = useState(false)
    const [indeterminate, setIndeterminate] = useState(false)

    useLayoutEffect(() => {
        const isIndeterminate = selectedRows.length > 0 && selectedRows.length < data.length
        setChecked(selectedRows.length === data.length)
        setIndeterminate(isIndeterminate)
        if (checkbox.current) {
            checkbox.current.indeterminate = isIndeterminate
        }
    }, [selectedRows, data.length])

    function toggleAll() {
        onRowSelected(checked || indeterminate ? [] : data.map((d) => d.id))
        setChecked(!checked && !indeterminate)
        setIndeterminate(false)
    }

    return (
        <table className="w-full">
            <thead className="nv-thead sticky">
                <tr>
                    <th scope="col" className="w-12 px-8">
                        <input
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                            ref={checkbox}
                            checked={checked}
                            onChange={toggleAll}
                        />
                    </th>
                    {columns.map((col) => (
                        <th
                            key={col}
                            scope="col"
                            className="py-2.5 px-3 text-left text-sm font-medium text-gray-900 whitespace-nowrap"
                        >
                            <button className="group inline-flex">
                                {col}
                                <span className="invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible">
                                    <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                                </span>
                            </button>
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
                {data.map((patient: Novoic.CombinedParticipantItem) => (
                    <tr
                        key={patient.id}
                        className="hover:bg-[#f7f9ff] cursor-pointer transition-all"
                        onClick={(e) => onRowClicked(e, patient.id)}
                    >
                        <td className="relative w-12 px-8 sm:w-16 sm:px-8">
                            {selectedRows.indexOf(patient.id) > -1 && (
                                <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                            )}
                            <input
                                type="checkbox"
                                className="top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
                                value={patient.id}
                                checked={selectedRows.indexOf(patient.id) > -1}
                                onChange={(e) => {
                                    onRowSelected(
                                        e.target.checked
                                            ? [...selectedRows, patient.id]
                                            : selectedRows.filter((id) => id !== patient.id)
                                    )
                                }}
                            />
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 w-[60px]" role="presentation">
                            {formatParticipant(
                                patient.studies?.[0]?.external_id,
                                patient.name,
                                patient.assignment?.recommend
                            )}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm">
                            {formatStatus(
                                patient.assignment?.status,
                                patient.assignmentInvitation !== undefined,
                                patient.assignment?.scores?.total?.score,
                                patient.assignment?.scores?.total?.level,
                                patient.assignment?.fact_start_time
                            )}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600">
                            {formatOutput(patient.gender)}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600">
                            {formatAge(patient.birth_date)}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-600">
                            {formatOutput(patient.language || 'English')}
                        </td>
                        <td className="px-3 py-4 text-sm text-gray-600">
                            {formatOutput(patient.races?.join(', '))}
                        </td>
                        <td className="px-3 py-4 text-sm text-gray-600">
                            {formatOutput(patient.education_level || "Backelor's")}
                        </td>
                        <td className="px-3 py-4 text-sm text-gray-600">
                            {formatOutput(patient.location)}
                        </td>
                        <td className="px-3 py-4 text-sm text-gray-600">
                            {formatContacts(patient.phone, patient.email)}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}
